export const FeaturesMenuItems = [
  {
    id: 1,
    title: "for sellers",
    path: "/betterseller/",
    cName: 'dropdown-link',
    description: 'Work in Sales? Find a more efficient way to manage your customer communications.'
  },
  {
    id: 2,
    title: "for customers",
    path: "/bettercustomer/",
    cName: 'dropdown-link',
    description: 'Deal with mulitple suppliers? There is a better way to collaborate with your suppliers.'
  },
];