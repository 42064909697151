import React from "react"
import SocialLinks from "../../constants/socialLinksFooter"
import logo from "../../assets/sc_mark.png"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <footer class="text-sm from-sc-secondary-light-blue-100 bg-gradient-to-b">
      <svg class="flip-x-y-footer" viewBox="0 0 1428 174" version="1.1" xmlns="http://www.w3.org/2000/svg" >
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g transform="translate(-2.000000, 44.000000)" fill="#FFFFFF" fill-rule="nonzero">
                  <path d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496" opacity="0.100000001"></path>
                  <path d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z" opacity="0.100000001"></path>
                  <path d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z" id="Path-4" opacity="0.200000003"></path>
              </g>
              <g transform="translate(-4.000000, 76.000000)" fill="#FFFFFF" fill-rule="nonzero">
                  <path d="M0.457,34.035 C57.086,53.198 98.208,65.809 123.822,71.865 C181.454,85.495 234.295,90.29 272.033,93.459 C311.355,96.759 396.635,95.801 461.025,91.663 C486.76,90.01 518.727,86.372 556.926,80.752 C595.747,74.596 622.372,70.008 636.799,66.991 C663.913,61.324 712.501,49.503 727.605,46.128 C780.47,34.317 818.839,22.532 856.324,15.904 C922.689,4.169 955.676,2.522 1011.185,0.432 C1060.705,1.477 1097.39,3.129 1121.236,5.387 C1161.703,9.219 1208.621,17.821 1235.4,22.304 C1285.855,30.748 1354.351,47.432 1440.886,72.354 L1441.191,104.352 L1.121,104.031 L0.457,34.035 Z"></path>
              </g>
          </g>
      </svg> 
      <div class="mx-auto px-6 scmd:px-20 sclg:px-24 scxl:px-32">
        {/* Footer Menu */}
        <div class="w-full flex flex-col scmd:flex-row">
          {/* Logo goes here */}
          <div class="flex-1 mb-6 text-black">
            <Link to="/">
              <img class="h-10" src={logo} alt="logo"  />
            </Link>
          </div>
          {/* Core Menu goes here */}
          <div class="flex-1">
            <p class="uppercase font-bold text-gray-600">Quick Links</p>
            <ul class="list-reset mb-6">
              <li class="mt-2 inline-block mr-2 scmd:block scmd:mr-0">
                <Link to="/customer" className="no-underline text-gray-600 hover:text-sc-secondary-dark-blue">Customer Feedback</Link>
              </li>
              <li class="mt-2 inline-block mr-2 scmd:block scmd:mr-0">
                <Link to="/seller" className="no-underline text-gray-600 hover:text-sc-secondary-dark-blue">Seller Feedback</Link>
              </li>
            </ul>
          </div>
          <div class="flex-1">
            <p class="uppercase font-bold text-gray-600">Features</p>
            <ul class="list-reset mb-6">
              <li class="mt-2 inline-block mr-2 scmd:block scmd:mr-0">
                <Link to="/bettercustomer" className="no-underline text-gray-600 hover:text-sc-secondary-dark-blue">For Customers</Link>
              </li>
              <li class="mt-2 inline-block mr-2 scmd:block scmd:mr-0">
                <Link to="/betterseller" className="no-underline text-gray-600 hover:text-sc-secondary-dark-blue">For Sellers</Link>
              </li>
              {/* <li class="mt-2 inline-block mr-2 scmd:block scmd:mr-0">
                <Link to="/" className="no-underline hover:underline text-gray-800 hover:text-green-500">Link 3</Link>
              </li> */}
            </ul>
          </div>
          {/* <div class="flex-1">
            <p class="uppercase font-bold text-gray-500 scmd:mb-6">Resources</p>
            <ul class="list-reset mb-6">
              <li class="mt-2 inline-block mr-2 scmd:block scmd:mr-0">
                <Link to="/" className="no-underline hover:underline text-gray-800 hover:text-green-500">Link 1</Link>
              </li>
              <li class="mt-2 inline-block mr-2 scmd:block scmd:mr-0">
                <Link to="/" className="no-underline hover:underline text-gray-800 hover:text-green-500">Link 2</Link>
              </li>
            </ul>
          </div> */}
          <div class="flex-1">
            <p class="uppercase font-bold text-gray-600">Company</p>
            <ul class="list-reset mb-6">
              <li class="mt-2 inline-block mr-2 scmd:block scmd:mr-0">
                <Link to="/about" className="no-underline text-gray-600 hover:text-sc-secondary-dark-blue">About</Link>
              </li>
              {/* <li class="mt-2 inline-block mr-2 scmd:block scmd:mr-0">
                <Link to="/" className="no-underline hover:underline text-gray-800 hover:text-green-500">Link 2</Link>
              </li>
              <li class="mt-2 inline-block mr-2 scmd:block scmd:mr-0">
                <Link to="/" className="no-underline hover:underline text-gray-800 hover:text-green-500">Link 3</Link>
              </li>
              <li class="mt-2 inline-block mr-2 scmd:block scmd:mr-0">
                <Link to="/" className="no-underline hover:underline text-gray-800 hover:text-green-500">Link 4</Link>
              </li> */}
            </ul>
          </div>

        </div>
        {/* Footer Legal, Contact & Social */}
        <div class="w-full flex flex-col scmd:flex-row">
          <div class="flex-1 mb-1 text-black">
            <span>
              <SocialLinks styleClass="mt-0 float-left pr-4"/>
              <Link to="/legal" className="pr-4 text-sm no-underline text-gray-600 hover:text-sc-secondary-dark-blue">Privacy & Legal</Link>
              <Link to="/contact" className="text-sm no-underline text-gray-600 hover:text-sc-secondary-dark-blue">Contact Us</Link>
              
            </span>
            
          </div>
          {/* <div class="flex-1 mb-1 text-black">
            <SocialLinks styleClass="mt-0 mr-auto mb-4 ml-auto"/>
          </div> */}
        </div>
        {/* Footer Legl */}
        <div class="w-full flex flex-col scmd:flex-row py-1">
          <p class="text-xs text-sc-secondary-dark-blue capitalize font-normal">
            &copy; copyright {new Date().getFullYear()} StickChart, LLC. All rights reserved.
          </p> 
        </div>
        
        
      </div>
    </footer>
    )
}

export default Footer
