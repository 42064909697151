
import React from "react"
import {
  FaFacebookSquare,
  FaLinkedin,
  FaYoutube,
  FaTwitterSquare,
} from "react-icons/fa"

const data = [
  {
    id: 1,
    icon: <FaLinkedin></FaLinkedin>,
    url: "https://www.linkedin.com/company/stickchart/",
    name: "LinkedIn"
  },
  // {
  //   id: 2,
  //   icon: <FaFacebookSquare></FaFacebookSquare>,
  //   url: "https://www.facebook.com",
  // },
  // {
  //   id: 3,
  //   icon: <FaYoutube></FaYoutube>,
  //   url: "https://www.youtube.com",
  // },
  // {
  //   id: 4,
  //   icon: <FaTwitterSquare></FaTwitterSquare>,
  //   url: "https://www.twitter.com",
  // },
]
const links = data.map(link => {
  return (
    <li key={link.id} class="px-2">
      <a href={link.url} rel="noopener" aria-label={link.name} target="_blank" className=" transition duration-500 ease-in-out text-2xl text-gray-600 hover:text-sc-secondary-dark-blue transform hover:-translate-y-1 hover:scale-110">
        {link.icon}
      </a>
    </li>
  )
})

export default ({ styleClass }) => {
  return (
    <ul className={`${styleClass ? styleClass : ""}`}>{links}</ul>
  )
}
