import React, { useEffect, useState, useRef } from "react"
import logo from "../../assets/sc_logo.png"
import { FaBars } from 'react-icons/fa'
import { Link } from "gatsby"
import FeaturesDropdown from './featuresdropdown'


const Navbar = ({toggleSidebar}) => {
  // *** START ***
  // Added below to support Dropdown
  const [dropdown, setDropdown] = useState(false);

  const handleClick = () => setDropdown(!dropdown);

  const onMouseEnter = () => {
    setDropdown(true);
  };

  const onMouseLeave = () => {
    setDropdown(false);
  };
  // *** END ***

  // Section Below Added to determine when the page is scrolled to change background of Navbar from Transparent to White
  const [navBackground, setNavBackground] = useState(false)
  const navRef = useRef()
  navRef.current = navBackground
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 20 // # 20 can be adjusted, the higher the number the further the scroll before changing background
      if (navRef.current !== show) {
        setNavBackground(show)
      }
    }
    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <header class={`${navBackground?"fixed w-full z-30 top-0 bg-white shadow-2xl":"fixed w-full z-30 top-0"}`}> 
      
      <div class="max-w-7xl mx-auto flex flex-wrap items-center justify-between mt-0 py-4 px-6">
        <div class="flex items-center">
          <Link to="/">
              <img class="w-52 sclg:w-64 mb-1.5" src={logo} alt="StickChart Logo"  />
          </Link>
        </div>
        <div class="block scmd:hidden">
          <button type="button" class="flex items-center p-0 text-3xl text-gray-600 hover:text-sc-secondary-dark-blue cursor-pointer" onClick={toggleSidebar}> 
            <FaBars></FaBars>
          </button>
        </div>
        <div class="w-full flex-grow scmd:flex scmd:items-center scmd:w-auto hidden mt-0 scmd:mt-0 bg-white scmd:bg-transparent text-gray-600 scmd:p-0 z-20" id="nav-content">
          <ul className="capitalize list-reset list-none scmd:flex justify-end flex-1 items-center">
            <li className="flex align-items" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={handleClick}>
              <Link className='nav-links-dropdown'>
                Features
              </Link> 
              {/* dropdown menu controlled here */}
              {dropdown && <FeaturesDropdown />}
            </li>
            <li className="flex align-items">
              <Link className='nav-links' to="/contact">
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <hr class="border-b border-gray-100 opacity-25 my-0 py-0" />
    </header>
  )
}

export default Navbar
