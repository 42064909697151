import React, { useState } from "react"
import PropTypes from "prop-types"
import Footer from "./footer"
// import Navbar from "./navbar"
// import Sidebar from "./sidebar"
import Navbar from "./navbarv2"
import Sidebar from "./sidebarv2"
import "../../styles/global.css"
import Cookies from 'universal-cookie';
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import CookieConsent from "react-cookie-consent";
import { Link } from "gatsby"


//define Customer API URL call
// let baseurl = 'http://scsitev1apis-env.eba-hnxtukew.us-east-2.elasticbeanstalk.com'
// let baseurl = 'https://validation.stickchart.dev'
// let baseurl = 'http://localhost:8081'
let baseurl = process.env.GATSBY_API_TARGET
let findsessionurl = baseurl + '/api/sessions/'
let access_token = process.env.GATSBY_API_KEY

if (process.env.GATSBY_ACTIVE_ENV === 'development') {
  console.log("***LOG RESPONSE*** GATSBY_API_KEY: " + process.env.GATSBY_API_KEY)
  console.log("***LOG RESPONSE*** API_KEY: " + process.env.API_KEY)
  console.log("***LOG RESPONSE*** NODE_ENV: " + process.env.NODE_ENV)
  console.log("***LOG RESPONSE*** ACTIVE_ENV: " + process.env.GATSBY_ACTIVE_ENV)
}

const cookies = new Cookies();

//Used to set expiration of the Cookie 3 years out from today's date
const current = new Date();
const nextYear = new Date();
nextYear.setFullYear(current.getFullYear() + 3);

let vals = {
    theme: "some text",
    server: "more text",
    enterAsSend: "even more text",
  }

let settings = Object.assign({}, vals);
settings.LocalStorage = true;

//Grab Session ID Values from Local Storage
const sessionID = typeof window !== 'undefined' && window.localStorage.getItem('stickchart:_sc_id');;

if (process.env.GATSBY_ACTIVE_ENV === 'development') {
  console.log("***LOG RESPONSE*** **SessionID** - " + sessionID)
}

if(sessionID ===null||
  sessionID===undefined){
    //No Session Value Stored
    //creating UUID Session ID
    let newSessionID = uuidv4()
    //Writing the SessionID to localStorage
    typeof window !== 'undefined' && window.localStorage.setItem('stickchart:_sc_id', newSessionID);
    //Committing the SessionID & User IP Address information to the backend
    axios.get('https://api.ipstack.com/check?access_key=7073af921e485c60c5d5e7dbf2b8a6a7')
      .then((response) => {
        //Call API to create the Session Record
        axios.post(findsessionurl, {
          session_id: newSessionID,
          user_ip: response.data.ip,
          continent_code: response.data.continent_code,
          country_code: response.data.country_code,
          region_code: response.data.region_code,
          city: response.data.city,
          zip: response.data.zip,
        }, {
          headers: {
            // Pass api key in via auth header
            'Authorization': 'Api-Key ' + access_token
          }
        })
        .then((response) => {
          if (process.env.GATSBY_ACTIVE_ENV === 'development') {
            console.log("***LOG RESPONSE*** **Axios Response** " + JSON.stringify(response));
          }
        }, (error) => {
          if (process.env.GATSBY_ACTIVE_ENV === 'development') {
            console.log("***LOG RESPONSE*** **Axios Error** " + JSON.stringify(error));
          }
        })
      });
  } else {  //Session Value does exist on device
    //API Call to verify the Session ID exists in the backend
    axios.get(findsessionurl + sessionID,
      {
        headers: {
          // Pass api key in via auth header
          'Authorization': 'Api-Key ' + access_token
        },
        validateStatus: false,  //the validateStatus: false allows this call to see any 404 errors, which we want to deal with
      },
      )  
      .then((response) => {
        if (process.env.GATSBY_ACTIVE_ENV === 'development') {
          console.log("***LOG RESPONSE*** **Axios Response** " + JSON.stringify(response))
        }
        if(response.status===404){
          //since SessionID does not exist in the Database, go ahead and create it
          //first step is to obtain IP address of user and related information
          axios.get('https://api.ipstack.com/check?access_key=7073af921e485c60c5d5e7dbf2b8a6a7')
          .then((response) => {
            //Call API to create the Session Record
            axios.post(findsessionurl, {
              session_id: sessionID,
              user_ip: response.data.ip,
              continent_code: response.data.continent_code,
              country_code: response.data.country_code,
              region_code: response.data.region_code,
              city: response.data.city,
              zip: response.data.zip,
            }, {
              headers: {
                // Pass api key in via auth header
                'Authorization': 'Api-Key ' + access_token
              }
            })
            .then((response) => {
              if (process.env.GATSBY_ACTIVE_ENV === 'development') {
                console.log("***LOG RESPONSE*** **Axios Response** " + JSON.stringify(response));
              }
            }, (error) => {
              if (process.env.GATSBY_ACTIVE_ENV === 'development') {
                console.log("***LOG RESPONSE*** **Axios Error** " + JSON.stringify(error));
              }
            })
          });
        } else {
          // console.log("**SessionID - Successfully located SessionID in the backend via API** ")
        }
      });
  }


const Layout = ({ children }) => {
  const [isOpen,setIsOpen] = useState(false);
  const toggleSidebar=()=>{
    setIsOpen(!isOpen)
  }

  

  return (
    <> 
      {/* this div should only show on mobile, when in landscape mode - message to tell user to switch to portrait mode*/}
      <div id="turn">
        <div class="flex w-screen">
          <div class="container pt-24 flex flex-col items-center mx-auto my-auto justify-center content-center">
            <div class="text-sc-primary-brown w-3/4 mx-auto text-center">
              <h4>
                Please rotate your device!  
              </h4>
            </div>
            <div class="text-sc-primary-brown w-3/4 mx-auto text-center">
              <h4>
                StickChart not optimized for landscape viewing.
              </h4>
            </div>
          </div>
        </div>
      </div>

      {/* this is the primary div/container to show the website */}
      <div id="stickchart">
        <Navbar toggleSidebar={toggleSidebar}/>
        <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
        <CookieConsent
            location="bottom"
            buttonText="I Understand"
            // enableDeclineButton
            // declineButtonText="Decline"
            cookieName="_sc_cookie_consent"
            style={{justifyContent: "center", background: "rgba(255,244,189,1)", color: "rgba(163,142,116,1)", fontSize: "14px" }}
            buttonStyle={{ color: "#FFFFFF", backgroundColor: "#7289da", margin: "5px 0 10px 0", ontSize: "14px" }}
            contentStyle={{flex: "1 1 100%", margin: "10px 0 0 0", textAlign: "center"}}
            expires={150}>
            <>
              By Using StickChart, you agree to our 
              <Link class="text-sc-secondary-light-blue" to="/legal"> Policies</Link>
            </>
        </CookieConsent>
        {children}
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
