import React from "react"
import { Link } from "gatsby"

const data = [
  {
    id: 1,
    title: "for sellers",
    path: "/betterseller/",
    description: 'Work in Sales? Find a more efficient way to manage your customer communications.'
  },
  {
    id: 2,
    title: "for customers",
    path: "/bettercustomer/",
    description: 'Deal with mulitple suppliers? There is a better way to collaborate with your suppliers.'
  },
  {
    id: 3,
    title: "contact",
    path: "/contact/",
  },
]

// Added the 'class="px-8"' to the li below to ensure spacing for the items on the Nav Bar - adjust as needed
const tempLinks = data.map(link => {
  return (
    <li key={link.id}> 
      <Link to={link.path}>
        {link.title}
        <div class="-mt-2 px-10 leading-3 pb-4">
          <span class="text-sm leading-3 tracking-tight normal-case">{link.description}</span>
        </div>
      </Link>
      
      
    </li>
  )
})


export default ({ styleClass }) => {
  return (
    <ul className={`${styleClass ? styleClass : ""}`}>
        {tempLinks}
    </ul>
  )
}
