import React from "react"
import Links from "../../constants/linksv2"
import SocialLinks from "../../constants/socialLinks"
import { FaTimes } from "react-icons/fa"
import { Link } from "gatsby"
const Sidebar = ({isOpen,toggleSidebar}) => {
  

  return (
    <aside className={`sidebar ${isOpen?"show-sidebar":''}`}>
      <button className="close-btn" name="close-sidebar" aria-label="close-sidebar" onClick={toggleSidebar}>
        <FaTimes />
      </button>
      <div className="side-container">
        <ul className={`${isOpen?"sidebar-links":""}`}>
            <li key="sidebar-home" class="scmd:pl-10 hover:text-sc-secondary-dark-blue "> 
              <Link to="/">Home</Link>
            </li>
        </ul>
        <Links styleClass={`${isOpen?"sidebar-links":""}`} />
        <SocialLinks styleClass={`${isOpen?"sidebar-icons":""}`} />
      </div>
    </aside>
    )
}

export default Sidebar
